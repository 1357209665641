<template>
    <el-row>
        <el-col :span="18">
            <h2 class="page-title">Автомобили</h2>
        </el-col>
    </el-row>

    <template v-for="company in companies">
        <el-row>
            <el-col :span="24">
                <h4>{{company.name}}</h4>
            </el-col>
            <template v-for="department in company.departments">
                <el-col :span="4">
                    <el-button @click="router.push('/companies/' + company.id + '/departments/' + department.id + '/autos')">{{department.name}}</el-button>
                </el-col>
            </template>
        </el-row>
    </template>


</template>
<script setup>
import {inject, ref} from "vue";
import {useRouter} from "vue-router";

const router = useRouter()
const tx          = inject('tx');
const companies   = ref([]);
tx.service.query.v1.get('/companies').then(companyResponse => {
    companyResponse.forEach(company => {
        tx.service.query.v1.get('/companies/' + company.id + '/departments').then(departmentResponse => {
            company.departments = departmentResponse
            companies.value.push(company)
        })
    })
})
</script>
