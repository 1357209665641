<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Редактировать техосмотр - {{toName}}</span>
                <el-button @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/technical-cards')" class="right-button" type="danger" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
            class="form-max-width"
        >

            <el-row :gutter="15">
                <el-col :span="8" v-if="column.number">
                    <el-form-item label="Номер" prop="number">
                        <el-input type="text" v-model="form.number" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.started">
                    <el-form-item label="Дата выдачи" prop="started" class="field-date-number first">
                        <el-date-picker
                            v-model="form.started"
                            type="date"
                            format="DD.MM.YYYY"
                            value-format="DD.MM.YYYY"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.active">
                    <el-form-item label="Активность" >
                        <el-switch v-model="form.active" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">
                            Редактировать техосмотр
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</template>

<script setup>
import {inject, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const tx       = inject('tx')
const route    = useRoute()
const router   = useRouter()
const toName = ref('')
const htmlForm = ref(null)

const column = reactive({
    number  : false,
    started : false,
    active  : false,
})

tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/technical-cards/' + route.params.to_id).then(to => {
    toName.value = (to.to.number || '')
    to.fields.forEach(el => {
        column[el] = true
    })
    form.number  = to.to.number
    form.started = to.to.started
    form.active  = !!to.to.active
})

const form = reactive({
    number  : null,
    started : null,
    active  : null,
})

const rules = reactive({
    number: {required: true, message: 'Поле "Номер" обязательно для заполнения', trigger: 'blur'},
    started: {required: true, message: 'Поле "Дата выдачи" обязательно для заполнения', trigger: 'blur'},
})

const submit = function(){
    htmlForm.value.validate((isValid) => {
        if(isValid){
            tx.service.query.v1.put('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/technical-cards/' + route.params.to_id, form).then(() => {
                router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/technical-cards')
                tx.service.successEdit()
            })
        }
    })
}
</script>
