<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Добавить авто - {{department.name}}</span>
                <el-button @click="close" type="danger" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
        >
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item label="Марка" prop="brand">
                        <el-input type="text" v-model="form.brand" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Модель" prop="model">
                        <el-input type="text" v-model="form.model" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Год выпуска" prop="year">
                        <el-input type="text" v-maska data-maska="####" v-model="form.year" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item label="Гос номер" prop="number">
                        <!-- через | задается еще 1 токен, на случай если будет нужна маска по конкретным буквам -->
                        <el-input type="text" placeholder="A 000 AA 00X" v-maska data-maska="@ ### @@ ##0" data-maska-tokens="0:[0-9]:optional" v-model="form.number"/>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Цвет" prop="color">
                        <el-input type="text" v-model="form.color" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Статус" prop="status" class="field-phone-number first">
                        <el-select v-model="form.status" style="width: 100%;">
                            <el-option
                                v-for="status in statuses"
                                :key="status.key"
                                :label="status.name"
                                :value="status.key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">
                            Добавить авто
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</template>

<script setup>
import {inject, reactive, ref} from "vue";
import { vMaska } from "maska"
import {useRoute, useRouter} from "vue-router";

const tx = inject('tx')
const route = useRoute()
const router = useRouter()
const htmlForm = ref(null)
const department    = ref({
    id  : 0,
    name: ''
})
tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id).then(res => {
    department.value = res;
})
const statuses = [
    {key : 'work', name : 'Работает'},
    {key : 'dtp', name : 'ДТП'},
]
const form =  reactive({
    brand     : '',
    model     : '',
    year      : '',
    number    : '',
    status    : 'work'
})

const rules = reactive({
    brand: {required: true, message: 'Поле "Марка" обязательно для заполнения', trigger: 'blur'},
    model: {required: true, message: 'Поле "Модель" обязательно для заполнения', trigger: 'blur'},
    status: {required: true, message: 'Поле "Статус" обязательно для заполнения', trigger: 'blur'},
    year: [
        {required: true, message: 'Поле "Год выпуска" обязательно для заполнения', trigger: 'blur'},
        {min: 4, max: 4, message: 'Поле "Год выпуска" должно состоять из 4 символов', trigger: 'blur'},
    ],
    number: [
        {required: true, message: 'Поле "Гос номер" обязательно для заполнения', trigger: 'blur'},
        {min: 11, max: 12, message: 'Поле "Гос номер" должно быть указано в формате A 000 AA 00X', trigger: 'blur'}
    ],
})

const submit = function(){
    htmlForm.value.validate((isValid) => {
        if(isValid){
            tx.service.query.v1.post('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/create', form).then(() => {
                tx.service.successAdd()
                close()
            })
        }
    })
}
const close = () => {
    router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos')
}

</script>
