<template>
    <el-row>
        <el-col :span="18">
            <h2 class="page-title">Автомобили - {{department.name}}</h2>
        </el-col>
        <el-col :span="6">
            <el-button
                class="right-button"
                @click="router.push(createAutoUrl)"
                v-if="tx.service.user.right('create_auto',route.params.department_id)"
            >Добавить авто</el-button>
        </el-col>
    </el-row>
    <el-row style="padding: 15px 0;">
        <el-col :span="4">
            <el-button @click="system.filter.visible = true">Фильтр</el-button>
        </el-col>
        <el-col :span="20" style="display: flex; justify-content: end;">
            <el-pagination
                style="margin-left: auto;"
                v-model:current-page="system.pagination.current"
                v-model:page-size="system.pagination.pageSize"
                :page-sizes="system.pagination.sizes"
                :hide-on-single-page="!system.pagination.total"
                :layout="system.pagination.layout"
                :total="system.pagination.total"
                @size-change="start"
                @current-change="start"
            />
        </el-col>
    </el-row>
    <el-row style="padding: 15px 0;">
        <el-col :span="4" v-if="tx.service.user.right('balance_department',route.params.department_id)">
                Баланс : {{balance.toLocaleString('ru')}}
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="24">
            <el-table v-if="loaded" :data="autos" class="table-max-width">
                <el-table-column label="#" width="80">
                    <template #default="scope" v-if="showExpand">
                        <el-popover
                            v-model:visible="scope.row.visible"
                            popper-class="context-table-menu"
                            placement="right"
                            trigger="click"
                            width="200px"
                        >
                            <template #reference>
                                <el-icon class="icon-cursor-pointer"><Expand /></el-icon>
                            </template>
                            <contextElement v-for="item in context"
                                            :data="item.data(scope.row.id)"
                                            @use="item.event(scope.row)"
                            />
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column label="Авто" v-if="column.brand" width="200">
                    <template #default="scope">
                        {{scope.row.brand}} {{scope.row.model}}
                    </template>
                </el-table-column>
                <el-table-column prop="number" label="Гос номер" v-if="column.number" width="120"/>
                <el-table-column prop="color" label="Цвет"  v-if="column.color" width="100"/>
                <el-table-column prop="year" label="Год выпуска" v-if="column.year" width="100"/>
                <el-table-column label="Статус" v-if="column.status" width="100">
                    <template #default="scope">
                        {{statuses[scope.row.status]}}
                    </template>
                </el-table-column>
                <el-table-column label="Оплата" v-if="column.pay" width="180">
                    <template #default="scope">
                        <div v-for="pay in scope.row.pays">
                            {{pay.period}}<br> {{pay.total}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Водитель" v-if="column.driver" width="180">
                    <template #default="scope">
                        <div v-for="driver in scope.row.drivers">
                            {{driver.name}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Разрешение" v-if="column.license" width="140">
                    <template #default="scope">
                        <div v-for="license in scope.row.licenses">
                            {{license.serial}} {{license.number}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Технический осмотр" v-if="column.to" width="140">
                    <template #default="scope">
                        <div v-for="tc in scope.row.tos">
                            {{tc.number}}<br>{{tc.days}}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column label="Активность" v-if="column.active" width="110">
                    <template #default="scope">
                        {{scope.row.active ? 'Да' : 'Нет'}}
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>

    <el-drawer v-model="drawer.visible"
               modal-class="custom-drawer"
               size="80%"
               :withHeader="false"
               show-close
               destroy-on-close
               :close-on-click-modal="false"
               :close-on-press-escape="false"
    >
        <component :is="child_component" />
    </el-drawer>

    <el-dialog v-model="system.filter.visible" :show-close="false">
        <template #header>
            <div style="display: flex;margin-right: -15px;">
                <span class="page-title">Укажите данные для фильтрации</span>
                <el-button @click="system.filter.visible = false" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form :model="system.filter" ref="filterForm" label-position="top">
            <el-row :gutter="15">
                <el-col :span="8" v-if="column.brand">
                    <el-form-item label="Марка" prop="brand">
                        <el-input type="text" v-model="system.filter.brand"/>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.model">
                    <el-form-item label="Модель" prop="model">
                        <el-input type="text" v-model="system.filter.model"/>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.number">
                    <el-form-item label="Гос номер">
                        <el-input type="text" placeholder="A 000 AA 00X" v-model="system.filter.auto_number"/>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.status">
                    <el-form-item label="Статус" class="field-phone-number first">
                        <el-select v-model="system.filter.status" style="width: 100%;">
                            <el-option
                                    v-for="(status,key) in statuses"
                                    :key="key"
                                    :label="status"
                                    :value="key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.brand">
                    <el-form-item label="Состояние" class="field-phone-number first">
                        <el-select v-model="system.filter.state" style="width: 100%;">
                            <el-option
                                    v-for="(state,key) in states"
                                    :key="key"
                                    :label="state"
                                    :value="key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
              <el-col :span="8" v-if="column.active">
                    <el-form-item label="Активность">
                        <el-switch v-model="system.filter.active"/>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <el-row :gutter="15">
                <el-col :span="8"></el-col>
                <el-col :span="8">
                    <el-button type="danger" style="width: 100%;" @click="clearFilter">Отменить</el-button>
                </el-col>
                <el-col :span="8">
                    <el-button style="width: 100%;" @click="start">Применить</el-button>
                </el-col>
            </el-row>
        </template>
    </el-dialog>

</template>
<script setup>
import contextElement from '@/components/main/contextElement.vue'
import {computed, inject, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";

const tx            = inject('tx')
const route         = useRoute()
const router        = useRouter()
const departmentUrl = '/companies/' + route.params.company_id + '/departments/' + route.params.department_id
const autosUrl      = '/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos'
const createAutoUrl = '/companies/' + route.params.company_id + '/departments/' + route.params.department_id +  '/autos/create'
const drawer = reactive({
    visible : false,
    close : () => {
        router.push(autosUrl)
        drawer.visible = false;
    },
    open : function(){
        drawer.visible = true;
    }
})
const props         = defineProps({
    child_component: String
})
const department    = ref({
    id  : 0,
    name: ''
})
tx.service.query.v1.get(departmentUrl).then(res => {
    department.value = res;
})
const loaded = ref(false)
const column = reactive({
    brand  : false,
    model  : false,
    number : false,
    year   : false,
    color  : false,
    status : false,
    active : false,
    driver : false,
    license: false,
    to     : false,
    pay    : false,
})
const statuses = {
    work: 'Работает',
    dtp : 'ДТП'
}
const states = {
    ready  : 'Готов к выезду',
    pay    : 'Нет оплаты',
    to     : 'Нет техосмотра',
    driver : 'Нет водителя',
    license: 'Нет лицензии',
}
const autos    = ref([]);
const balance    = ref(0);
const system   = reactive({
    pagination: {
        current : 1,
        pageSize: 10,
        sizes   : [10, 20, 50, 100],
        total   : 1,
        layout  : "jumper, prev, pager, next, total, sizes",
    },
    filter    : {
      visible    : false,
      brand      : '',
      model      : '',
      auto_number: '',
      status     : '',
      active     : true,
      state      : '',
    }
});
const clearFilter = () => {
    system.filter = {
        visible    : false,
        brand      : '',
        model      : '',
        auto_number: '',
        status     : '',
        active     : true,
    }
    start()
}
const context = [
    {
        data : () => {
            return{
                privilege_code: 'update_auto',
                many_id       : route.params.department_id,
                title         : 'Редактировать'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + row.id)
            drawer.open()
            row.visible = false
        }
    },
    {
        data : () => {
            return{
                privilege_code: 'list_driver',
                many_id       : route.params.department_id,
                title         : 'Водители'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + row.id + '/drivers')
            drawer.open()
            row.visible = false
        }
    },
    {
        data : () => {
            return{
                privilege_code: 'list_license',
                many_id       : route.params.department_id,
                title         : 'Лицензии'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + row.id + '/licenses')
            drawer.open()
            row.visible = false
        }
    },
    {
        data : () => {
            return{
                privilege_code: 'list_to',
                many_id       : route.params.department_id,
                title         : 'Техосмотры'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + row.id + '/technical-cards')
            drawer.open()
            row.visible = false
        }
    },
    {
        data : (manyId) => {
            return{
                privilege_code: 'show_rate_auto',
                many_id       : route.params.department_id,
                title         : 'Тарифы авто'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/'+ route.params.department_id  + '/autos/' + row.id + '/rates')
            row.visible = false
        }
    },
    {
        data : (manyId) => {
            return{
                privilege_code: 'create_pay',
                many_id       : route.params.department_id,
                title         : 'Добавить оплату'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/'+ route.params.department_id  + '/autos/' + row.id + '/pays/create')
            row.visible = false
        }
    },
    {
        data : (manyId) => {
            return{
                privilege_code: 'show_all_pay',
                many_id       : route.params.department_id,
                title         : 'Оплаты'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/'+ route.params.department_id  + '/autos/' + row.id + '/pays')
            row.visible = false
        }
    },
]
const showExpand = computed(() => {
    let show = false;
    context.forEach(el => {
        if(tx.service.user.right(el.data().privilege_code, -1))
            show = true;
    })
    return show
})
const start = () => {
    if(props.child_component !== 'list-auto')
        drawer.open();
    else{
        drawer.close();
        loaded.value = false
        tx.service.query.v1.post(autosUrl, system, true).then(response => {
            system.filter.visible = false;
            tx.service.loading = false;
            if (response.status === 200) {
                response.json().then(result => {
                    result.fields.forEach(el => {
                        column[el] = true
                    })
                    system.pagination.total = result.count
                    balance.value           = result.balance
                    autos.value             = result.autos
                }).finally(() => {
                    loaded.value = true
                })
            } else if (response.status === 422) {
                response.json().then(result => {
                    tx.service.notify({
                        type   : 'error',
                        message: result.message,
                    })
                })
            } else {
                tx.service.error();
            }
        })
    }
}
watch(() => props.child_component,() => {
    start()
})
start()

</script>
