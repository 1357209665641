<template>
    <el-menu
        :default-active="route.meta['action']"
        unique-opened
        router
        v-if="tx.service.user.right('main')"
    >
        <el-menu-item  index="/">Главная</el-menu-item>
        <el-menu-item
            index="/companies"
            v-if="tx.service.user.right('show_company') || tx.service.user.right('create_company')"
        >Организации</el-menu-item>
        <el-menu-item v-if="tx.service.user.right('show_auto', -1)" index="/autos">Автомобили</el-menu-item>
        <el-menu-item v-if="tx.service.user.right('access_report_department', -1)" index="/reports">Отчеты</el-menu-item>
    </el-menu>
</template>
<script setup>

import {useRoute} from "vue-router";
import {inject} from "vue";

const route = useRoute();
const tx = inject('tx')

</script>
