<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Добавить тариф авто - {{auto.brand + ' ' + auto.model + ' ' + auto.number}}</span>
                <el-button @click="close" class="right-button" type="danger" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
            class="form-max-width"
            v-if="tx.service.user.right('create_rate_auto', route.params.department_id)"
        >
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item label="Наименование" prop="name">
                        <el-input type="text" v-model="form.name" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Стоимость" prop="price">
                        <el-input-number style="width: 100%;" :controls="false" v-model="form.price" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">Добавить тариф</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <access-denied v-else />
    </el-card>
</template>

<script setup>

import {inject, reactive, ref} from "vue";
import {useRoute,useRouter} from "vue-router";

const tx = inject('tx');
const route = useRoute()
const router = useRouter()
const htmlForm = ref(null)
const rules = reactive({
    name : { required: true, message: 'Поле "Наименование: обязательно для заполнения', trigger: 'blur' },
    price : { required: true, message: 'Поле "Стоимость" обязательно для заполнения', trigger: 'blur' },
});
const form = reactive({
    name : '',
    price : 0,
})
const auto = ref({
    id : 0,
    model : '',
    brand : '',
    number : ''
})

tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id).then(res => {
    auto.value = res;
})
const submit = function(){
    htmlForm.value.validate((isValid) => {
        if (isValid) {
            tx.service.query.v1.post('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/rates/create',form).then(() => {
                close()
                tx.service.successAdd()
            })
        }
    });
}

const close = () => {
    router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/rates')
}
</script>
