<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Добавить оплату - {{autoName}}</span>
                <el-button @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos')" class="right-button" type="danger" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
            class="form-max-width"
        >

            <el-row :gutter="15">

                <el-col :span="8">
                    <el-form-item label="Вариант расчета" class="field-phone-number first">
                        <el-select v-model="form.type" style="width: 100%;">
                            <el-option
                                v-for="(type,key) in payTypes"
                                :key="key"
                                :label="type"
                                :value="key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="Тариф" class="field-phone-number first">
                        <el-select v-model="form.rate" style="width: 100%;">
                            <el-option
                                v-for="rate in rates"
                                :key="rate.id"
                                :label="rate.name + ' (' + rate.price + ' руб)'"
                                :value="rate.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8">
                    <el-form-item label="Дата начала периода" class="field-date-number first">
                        <el-date-picker
                            v-model="form.start"
                            type="date"
                            format="DD.MM.YYYY"
                            value-format="DD.MM.YYYY"
                            :clearable="false"
                            :disabled="pay !== null"
                        />
                    </el-form-item>
                </el-col>

                <el-col :span="8" v-if="form.type === 'period'">
                    <el-form-item label="Дата окончания периода" class="field-date-number first">
                        <el-date-picker
                            v-model="form.end"
                            type="date"
                            format="DD.MM.YYYY"
                            value-format="DD.MM.YYYY"
                            :clearable="false"
                        />
                    </el-form-item>
                </el-col>

                <el-col :span="8" v-if="form.type === 'sum'">
                    <el-form-item label="Сумма">
                        <el-input-number style="width: 100%;" :controls="false" type="text" v-model="form.total" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="15">
                <el-col :span="8" v-if="true ||math.pays.length && form.end">
                    Оплачиваемый период :
                    <template v-for="pay in math.pays">
                        <p>
                            {{pay.period}} - {{pay.days}} дней - {{pay.amount}} руб.
                        </p>
                    </template>
                    <p><b>Итого к оплате - {{math.amount}} руб.</b></p>
                </el-col>
            </el-row>

            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">
                            Добавить оплату
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</template>

<script setup>
import {computed, inject, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import moment from 'moment';

const tx       = inject('tx')
const route    = useRoute()
const router   = useRouter()
const autoName = ref('')
const htmlForm = ref(null)
const rates    = ref([])
const pay      = ref(null)

const payTypes = {
    sum   : 'По сумме',
    period: 'По периоду'
}

tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id).then(auto => {
    autoName.value = (auto.brand || '') + ' ' + (auto.model || '') + ' ' + (auto.number || '')
})

tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/pays/create').then(pays => {
    if(pays.pay){
        pay.value = pays.pay
        form.end = pays.pay.end
        form.start = pays.pay.end
    }
    rates.value = pays.rates
    if(pays.rates.length)
        form.rate = rates.value[0].id
})

const price = computed(() => rates.value.filter(el => el.id == form.rate)[0].price)

const form = reactive({
    rate : '',
    type : 'period',
    start : moment().format('DD.MM.YYYY'),
    end : moment(moment().format('YYYY-MM-') + moment().daysInMonth()).format('DD.MM.YYYY'),
    total : 0,
})

const rules = reactive({
    total: {required: true, message: 'Поле "Сумма" обязательно для заполнения', trigger: 'blur'},
    end: {required: true, message: 'Поле "Дата окончания периода" обязательно для заполнения', trigger: 'blur'},
})

const getDayPrice = (date) => {
    let dayInMonth = date.daysInMonth();
    let priceDay   = Math.round((price.value / dayInMonth))
    return (10 - (priceDay % 10) + priceDay)
}
const getPricePeriod = (days,priceDay) => {
    let amount = days * priceDay
    if (amount > price.value)
        amount = price.value
    return amount
}
const math = computed(() => {
    let result = [];
    if(rates.value.length) {
        let start = moment(form.start, 'DD.MM.YYYY');
        let end   = moment(form.end, 'DD.MM.YYYY');
        let priceDay = getDayPrice(start);
        let days = 0
        let amount = 0
        let minusTotal = 0;
        if(pay.value && start.format('DD') != '01')
            minusTotal = pay.value.total

        if(start.isAfter(end)) {
            form.start = end.format('DD.MM.YYYY')
            start = moment(form.end, 'DD.MM.YYYY');
        }

        if(form.type == 'period') {
            let iterable = true;
            while (iterable) {
                let endDayMonth = moment(start.format('YYYY-MM-') + start.daysInMonth())
                priceDay = getDayPrice(start);

                if (endDayMonth.isBefore(end)) {
                    days   = endDayMonth.format('DD') - start.format('DD') + 1
                    amount = getPricePeriod(days, priceDay)
                    if((amount + minusTotal) >= price.value){
                        amount = price.value - minusTotal;
                        minusTotal = 0;
                    }
                    result.push({
                        amount: amount,
                        period: start.format('DD.MM.YYYY') + ' - ' + endDayMonth.format('DD.MM.YYYY'),
                        days  : days
                    })
                    start = moment(start.format('YYYY-MM-') + '01')
                    start.add(1, 'M');
                } else {
                    days     = end.format('DD') - start.format('DD') + 1
                    amount   = getPricePeriod(days, priceDay)
                    if((amount + minusTotal) >= price.value){
                        amount = price.value - minusTotal;
                        minusTotal = 0;
                        end = endDayMonth
                        form.end = endDayMonth.format('DD.MM.YYYY');
                        days     = endDayMonth.format('DD') - start.format('DD') + 1
                    }
                    result.push({
                        amount: amount,
                        period: start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY'),
                        days  : days
                    })
                    iterable = false
                }
            }
        }else if(form.type == 'sum' && form.total > 0){
            let total = 0;
            days = 0
            let pushed = true;
            while (total <= form.total - priceDay) {
                let endDayMonth = moment(start.format('YYYY-MM-') + start.daysInMonth())
                total += priceDay
                amount += priceDay
                days++;

                if(amount >= price.value || (amount + minusTotal) >= price.value) {

                    start = moment(start.format('YYYY-MM-') + start.daysInMonth())
                    if(!minusTotal) {
                        days   = start.daysInMonth()
                        amount = price.value
                    }

                }
                if(start.isSame(endDayMonth)){
                    let s = moment(start.subtract(days - 1,'d'))
                    if((amount + minusTotal) >= price.value && minusTotal) {
                        start = moment(form.start, 'DD.MM.YYYY');
                        amount = price.value - minusTotal;
                        s = moment(form.start, 'DD.MM.YYYY');
                        days = endDayMonth.format('DD') - s.format('DD') + 1
                        minusTotal = 0;
                    }
                    pushed = false;
                    result.push({
                        amount: amount,
                        period: s.format('DD.MM.YYYY') + ' - ' + endDayMonth.format('DD.MM.YYYY'),
                        days  : days
                    })
                    start.add(days - 1,'d')
                    priceDay = getDayPrice(start);
                    amount = 0
                    days = 0;
                }else{
                    pushed = true;
                }
                start.add(1,'d')
            }
            if(pushed){
                let rest = form.total - result.reduce((a, b) => a + b.amount, 0)
                result.push({
                    amount: rest,
                    period: moment(start.subtract(days,'d')).format('DD.MM.YYYY') + ' - ' + moment(start.add(days - 1,'d')).format('DD.MM.YYYY'),
                    days  : days
                })
            }
        }

    }

    return {pays : result, amount : result.reduce((a, b) => a + b.amount, 0)};
})

const submit = function(){
    htmlForm.value.validate((isValid) => {
        if(isValid){
            tx.service.query.v1.post('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/pays/create', form).then(() => {
                router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos')
                tx.service.successAdd()
            })
        }
    })
}
</script>
