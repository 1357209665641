import Company from "@/pages/companies/index.vue";

export default [
    {
        path     : '/companies/:company_id/employees/create',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'create-employee'})
    },
    {
        path     : '/companies/:company_id/employees/:employee_id',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'update-employee'})
    },
    {
        path     : '/companies/:company_id/employees',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'list-employee'})
    },
    {
        path     : '/companies/:company_id/employees/:employee_id/privileges',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'privilege-employee'})
    },
]
