<template>
    <el-card v-if="auto">
        <template #header>
            <div style="display: flex">
                <span class="page-title">Список тарифов авто - {{auto.brand + ' ' + auto.model + ' ' + auto.number}}</span>
                <el-button @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos')" class="right-button" type="danger" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-row>
            <el-col :span="18"></el-col>
            <el-col :span="6">
                <el-button
                    class="right-button"
                    @click="router.push('/companies/'+ route.params.company_id + '/departments/'+ route.params.department_id + '/autos/' + + route.params.auto_id +'/rates/create')"
                    v-if="tx.service.user.right('create_rate_auto', route.params.department_id)"
                >Добавить тариф</el-button>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-table :data="rates" class="table-max-width">
                    <el-table-column label="#" width="80">
                        <template #default="scope">
                            <el-popover v-model:visible="scope.row.visible" popper-class="context-table-menu" placement="right" trigger="click" v-if="showExpand">
                                <template #reference>
                                    <el-icon
                                        class="icon-cursor-pointer"
                                        v-if="tx.service.user.right('update_rate_auto', route.params.department_id)"
                                    ><Expand /></el-icon>
                                </template>
                                <contextElement v-for="item in context"
                                                :data="item.data(scope.row.id)"
                                                @use="item.event(scope.row)"
                                />
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="Название" />
                    <el-table-column prop="price" label="Стоимость" />
                    <el-table-column label="Активность">
                        <template #default="scope">
                            {{scope.row.active ? 'Да' : 'Нет'}}
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </el-card>
</template>
<script setup>
import {computed, inject, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import contextElement from '@/components/main/contextElement.vue'

const tx      = inject('tx')
const route   = useRoute()
const router  = useRouter()
const auto = ref({
    id : 0,
    model : '',
    brand : '',
    number : ''
})
const rates   = ref([])
tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id).then(res => {
    auto.value = res;
})
const get = function() {
    tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/rates').then(res => {
        rates.value = res.map(rate => {
            rate.visible = false;
            rate.edit = false;
            return rate
        })
    })
}
get()
const context = [
    {
        data : (manyId) => {
            return{
                privilege_code: 'update_rate_auto',
                many_id       : route.params.department_id,
                title         : 'Редактировать'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/rates/' + row.id)
        }
    }
]
const showExpand = computed(() => {
    let show = false;
    context.forEach(el => {
        if(tx.service.user.right(el.data().privilege_code, -1))
            show = true;
    })
    return show
})
</script>
