<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Список оплат - {{autoName}}</span>
                <el-button type="danger" @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos')" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-row>
            <el-col :span="18"></el-col>
            <el-col :span="6">
                <el-button
                    class="right-button"
                    @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/pays/create')"
                    v-if="tx.service.user.right('create_pay', route.params.department_id)"
                >Добавить оплату</el-button>
            </el-col>
        </el-row>
        <el-table :data="pays" v-if="loaded" class="table-max-width">
            <el-table-column label="#" width="40">
                <template #default="scope">
                    <el-popover
                        v-model:visible="scope.row.visible"
                        popper-class="context-table-menu"
                        placement="right"
                        trigger="click"
                        v-if="showExpand && scope.$index == 0"
                    >
                        <template #reference>
                            <el-icon
                                class="icon-cursor-pointer"
                            ><Expand /></el-icon>
                        </template>
                        <template v-for="item in context">
                            <contextElement
                                :data="item.data(scope.row.id)"
                                @use="item.event(scope.row)"
                            />
                        </template>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="period" label="Оплаченный период" v-if="column.period" width="250"/>
            <el-table-column prop="creator" label="Кто вносил" v-if="column.creator" width="250"/>
            <el-table-column prop="total" label="Сумма" v-if="column.total" width="120"/>
            <el-table-column prop="created_at" label="Дата внесения" v-if="column.created_at" width="120"/>
            <el-table-column prop="repair" label="Востановленная" width="140">
                <template #default="scope">
                    {{scope.row.repair ? 'Да' : 'Нет'}}
                </template>
            </el-table-column>
        </el-table>
    </el-card>
    <el-dialog
        v-model="deleted.show"
        title="Удалить оплату"
        width="30%"
    >
        <el-input type="textarea" v-model="deleted.reason"/>
        <template #footer>
          <span class="dialog-footer">
            <el-button type="danger" @click="notDelete">Отменить</el-button>
            <el-button type="success" @click="setDelete">Удалить</el-button>
          </span>
        </template>
    </el-dialog>

</template>
<script setup>
import {computed, inject, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import contextElement from '@/components/main/contextElement.vue'

const tx       = inject('tx')
const route    = useRoute()
const router   = useRouter()
const pays     = ref([])
const autoName = ref('')
const fields   = ref([])
const loaded   = ref(false)
const deleted  = reactive({
    show  : false,
    id    : 0,
    reason: '',
})
const column   = reactive({
    period    : false,
    creator   : false,
    total     : false,
    created_at: false,
})
const setDelete = () => {
    if(deleted.id > 0 && deleted.reason != ''){
        tx.service.query.v1.post('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/pays/' + deleted.id + '/delete',deleted).then(() => {
            get()
            notDelete()
        })
    }else{
        tx.service.notify({message : 'Необходимо указать причину удаления',type : 'error'})
    }
}
const notDelete = () => {
    deleted.show  = false
    deleted.id    = 0
    deleted.reason= ''
}
tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id).then(auto => {
    autoName.value = (auto.brand || '') + ' ' + (auto.model || '') + ' ' + (auto.number || '')
})
const get = function() {
    loaded.value = false
    tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id +  '/autos/' + route.params.auto_id + '/pays', true).then(response => {
        tx.service.loading = false;
        if (response.status === 200) {
            response.json().then(result => {
                result.fields.forEach(el => {
                    column[el] = true
                })
                pays.value = result.pays
            }).finally(() => {
                loaded.value = true
            })
        } else if (response.status === 422) {
            response.json().then(result => {
                tx.service.notify({
                    type   : 'error',
                    message: result.message,
                })
            })
        } else {
            tx.service.error();
        }
    })
}
get()
const context = [
    {
        data : (manyId) => {
            return{
                privilege_code: 'delete_pay',
                many_id       : route.params.department_id,
                title         : 'Удалить'
            }
        },
        event: (row) => {
            deleted.id = row.id
            deleted.show = true
            row.visible = false
            //router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/pays/' + row.id)
        }
    }
]
const showExpand = computed(() => {
    let show = false;
    context.forEach(el => {
        if(tx.service.user.right(el.data().privilege_code, -1))
            show = true;
    })
    return show
})
</script>
