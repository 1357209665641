<template>
    <el-card v-if="company">
        <template #header>
            <div style="display: flex;">
                <span class="page-title">Список подразделений организации - {{company.name}}</span>
                <el-button @click="router.push('/companies')" type="danger" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-row>
            <el-col :span="18"></el-col>
            <el-col :span="6">
                <el-button
                    class="right-button"
                    @click="router.push('/companies/'+ company.id + '/departments/create')"
                    v-if="tx.service.user.right('create_department', company.id)"
                >Добавить подразделение</el-button>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-table :data="departments" class="table-max-width">
                    <el-table-column label="#" width="80">
                        <template #default="scope">
                            <el-popover v-model:visible="scope.row.visible" popper-class="context-table-menu" placement="right" trigger="click" v-if="showExpand">
                                <template #reference>
                                    <el-icon
                                        class="icon-cursor-pointer"
                                        v-if="
                                            tx.service.user.right('update_department', scope.row.id) ||
                                            tx.service.user.right('create_rate_department', scope.row.id) ||
                                            tx.service.user.right('show_rate_department', scope.row.id)
                                        "
                                    ><Expand /></el-icon>
                                </template>
                                <contextElement v-for="item in context"
                                                :data="item.data(scope.row.id)"
                                                @use="item.event(scope.row)"
                                />
                            </el-popover>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="Название" />
                </el-table>
            </el-col>
        </el-row>
    </el-card>



</template>
<script setup>
import {computed, inject, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import contextElement from '@/components/main/contextElement.vue'

const tx = inject('tx');
const route = useRoute()
const router = useRouter()
const htmlForm    = ref(null)
const company = ref({
    id : 0,
    name : ''
})
const departments = ref([])

const get = function() {
    tx.service.query.v1.get('/companies/' + route.params.company_id).then(res => {
        company.value = res;
    })
    tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments').then(res => {
        departments.value = res.map(department => {
            department.visible = false;
            department.edit = false;
            return department
        })
    })
}
get()
const context = [
    {
        data : (manyId) => {
            return{
                privilege_code: 'update_department',
                many_id       : manyId,
                title         : 'Редактировать'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/' + row.id)
        }
    },
    {
        data : (manyId) => {
            return{
                privilege_code: 'show_rate_department',
                many_id       : manyId,
                title         : 'Тарифы подразделения'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/'+row.id+'/rates')
            row.visible = false
        }
    },
]
const showExpand = computed(() => {
    let show = false;
    context.forEach(el => {
        if(tx.service.user.right(el.data().privilege_code, -1))
            show = true;
    })
    return show
})

</script>
