<template>
    <el-row>
        <el-col :span="18">
            <h2 class="page-title">Списания - {{department.name}}</h2>
        </el-col>
        <el-col :span="6">
            <el-button
                class="right-button"
                @click="router.push(createWriteOffUrl)"
                v-if="tx.service.user.right('report_write_off',route.params.department_id)"
            >Добавить списание</el-button>
        </el-col>
    </el-row>
    <el-row style="padding: 15px 0;">
        <el-col :span="4">
            <el-form-item label="Активные">
                <el-switch v-model="activeWrite" @change="start"/>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="24">
            <el-table v-if="loaded" :data="writes" class="table-max-width">
                <el-table-column label="#" width="80">
                    <template #default="scope" v-if="showExpand">
                        <el-popover
                            v-model:visible="scope.row.visible"
                            popper-class="context-table-menu"
                            placement="right"
                            trigger="click"
                            width="200px"
                        >
                            <template #reference>
                                <el-icon class="icon-cursor-pointer"><Expand /></el-icon>
                            </template>
                            <contextElement v-for="item in context"
                                            :data="item.data(scope.row.id)"
                                            @use="item.event(scope.row)"
                            />
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="total" label="Сумма" width="160"/>
                <el-table-column prop="creator" label="Кто создал" width="200"/>
                <el-table-column prop="created_at" label="Когда создал" width="180"/>
                <el-table-column label="Активность" width="110">
                    <template #default="scope">
                        {{scope.row.active ? 'Да' : 'Нет'}}
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
    </el-row>

    <el-drawer v-model="drawer.visible"
               modal-class="custom-drawer"
               size="80%"
               :withHeader="false"
               show-close
               destroy-on-close
               :close-on-click-modal="false"
               :close-on-press-escape="false"
    >
        <component :is="child_component" />
    </el-drawer>

</template>
<script setup>
import contextElement from '@/components/main/contextElement.vue'
import {computed, inject, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";

const tx            = inject('tx')
const route         = useRoute()
const router        = useRouter()
const departmentUrl = '/companies/' + route.params.company_id + '/departments/' + route.params.department_id
const createWriteOffUrl = '/companies/' + route.params.company_id + '/departments/' + route.params.department_id +  '/reports/write-off/create'
const drawer = reactive({
    visible : false,
    close : () => {
        router.push(departmentUrl + '/reports/write-off')
        drawer.visible = false;
    },
    open : function(){
        drawer.visible = true;
    }
})
const props         = defineProps({
    child_component: String
})
const department    = ref({
    id  : 0,
    name: ''
})
tx.service.query.v1.get(departmentUrl).then(res => {
    department.value = res;
})
const loaded = ref(false)
const writes    = ref([]);
const activeWrite    = ref(true);

const context = [
    {
        data : () => {
            return{
                privilege_code: 'report_write_off',
                many_id       : route.params.department_id,
                title         : activeWrite.value ? 'Деактивировать' : 'Активировать'
            }
        },
        event: (row) => {
            tx.service.query.v1.post(departmentUrl + '/reports/write-off/'+row.id+'/reactive',{}).then(() => {
                start()
            })
            row.visible = false
        }
    },
]
const showExpand = computed(() => {
    let show = false;
    context.forEach(el => {
        if(tx.service.user.right(el.data().privilege_code, -1))
            show = true;
    })
    return show
})
const start = () => {
    if(props.child_component !== 'list-write-off')
        drawer.open();
    else{
        drawer.close();
        loaded.value = false
        tx.service.query.v1.post(departmentUrl + '/reports/write-off', {active : activeWrite.value}, true).then(response => {
            tx.service.loading = false;
            if (response.status === 200) {
                response.json().then(result => {
                    writes.value = result
                }).finally(() => {
                    loaded.value = true
                })
            } else if (response.status === 422) {
                response.json().then(result => {
                    tx.service.notify({
                        type   : 'error',
                        message: result.message,
                    })
                })
            } else {
                tx.service.error();
            }
        })
    }
}
watch(() => props.child_component,() => {
    start()
})
start()

</script>
