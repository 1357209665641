<template>
    <el-container>
        <el-header>
            <el-menu
                class="menu-user"
                mode="horizontal"
            >
                <h1 class="main-title">{{tx.data.company.name}}</h1>
                <el-sub-menu index="profile-group" v-if="tx.data.user.authorize">
                    <template #title>
                        <div class="profile-element">
                            <el-avatar :size="38" src="#"></el-avatar>
                            <span>{{tx.data.user.name}}</span>
                            <span>{{tx.data.user.position}}</span>
                        </div>
                    </template>
<!--                    <el-menu-item index="profile">Профиль</el-menu-item>-->
                    <el-menu-item index="exit" @click="logout">Выход</el-menu-item>
                </el-sub-menu>
                <el-menu-item v-else class="login-item">
                    <el-link href="#" @click.prevent="form.visible = true">Войти</el-link>
                </el-menu-item>
            </el-menu>
        </el-header>
        <el-container>
            <el-aside width="200px" v-if="tx.data.user.authorize">
                <el-scrollbar height="calc(100svh - 60px)">
                    <LeftMenu />
                </el-scrollbar>
            </el-aside>
            <el-main>
                <router-view />
            </el-main>
        </el-container>
    </el-container>
    <el-dialog v-model="form.visible" title="Войти" width="320px">
        <el-form
            ref="loginForm"
            :model="form"
            :rules="rules"
            label-position="top"
            @keyup="login"
        >
            <el-form-item label="Телефон" prop="phone">
                <el-input placeholder="+7 (###) ###-##-##" v-maska data-maska="+7 (###) ###-##-##" v-model="form.phone" />
            </el-form-item>
            <el-form-item label="Пароль" prop="password">
                <el-input type="password" v-model="form.password" />
            </el-form-item>
            <el-form-item>
                <el-button class="button-max-width color-taxi" @click="login">
                    Войти
                </el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script setup>
import tx from "@/system";
import {provide, reactive, ref} from "vue";
import {vMaska} from "maska"
import LeftMenu from '@/components/main/left-menu.vue'
import {useRoute, useRouter} from "vue-router";

const route  = useRoute()
const router = useRouter()

router.beforeEach((to, from, next) => {
    tx.service.app.authentication().then(() => next()).catch(() => {
        if(to.meta["auth"]){
            tx.service.user.clear()
            next('/');
        }else next();
    }).finally(() =>{
        tx.service.loading = false
    })
})

const loginForm = ref(null)
const form = reactive({
    visible : false,
    phone   : '',
    password: ''
})

const rules = reactive({
    phone       : [{ required: true, message: 'Пожалуйста укажите телефон', trigger: 'blur' }],
    password    : [{ min: 8, message: 'Длина пароля не может быть меньше 8 символов', trigger: 'blur' }],
})

const reset = function(){
    form.visible  = false
    form.phone    = ''
    form.password = ''
}

const login = function(){

    if(window.event.type == 'click' && form.visible == true || window.event.keyCode == '13' && form.visible == true) {
        loginForm.value.validate((isValid) => {
            if (isValid) {
                tx.service.loading = true
                fetch(tx.data.server + '/login', {
                    credentials: 'include',
                    method     : 'post',
                    headers    : {
                        'Content-Type'    : 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                        'X-XSRF-TOKEN'    : tx.data.user.token,
                        'Accept'          : 'application/json'
                    },
                    body       : JSON.stringify({
                        phone   : form.phone,
                        password: form.password
                    })
                }).then(response => {
                    if (response.status === 200) {
                        reset()
                        tx.service.user.clear()
                        tx.service.app.authentication()
                    } else if (response.status === 422)
                        tx.service.notify({type: 'error', message: 'Некорректные учетные данные'})
                    else if (response.status === 429)
                        tx.service.notify({type: 'error', message: 'Слишком много попыток. Попробуйте повторить через минуту'})
                    else
                        tx.service.notify({type: 'error', message: 'Проблема маршрута авторизации'})
                }).finally(() => {
                    tx.service.loading = false
                })
            }
        })
    }
}

const logout = function(){
    fetch(tx.data.server + '/logout',{
        credentials : 'include',
        method : 'post',
        headers : {
            'Content-Type'      : 'application/json',
            'X-Requested-With'  : 'XMLHttpRequest',
            'X-XSRF-TOKEN'      : tx.data.user.token,
            'Accept'            : 'application/json'
        }
    }).then(res => {
        if(res.status === 204) {
            tx.service.user.clear()
            router.push('/')
        }else tx.service.notify({type : 'error',message : 'Проблема выхода из системы'})
    })
}
provide('tx', tx);
</script>
