<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Редактировать разрешение - {{licenseName}}</span>
                <el-button @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/licenses')" class="right-button" type="danger" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
            class="form-max-width"
        >

            <el-row :gutter="15">
                <el-col :span="8" v-if="column.region">
                    <el-form-item label="Регион" prop="region">
                        <el-input type="text" v-model="form.region" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.company">
                    <el-form-item label="Лицензиар" prop="company">
                        <el-input type="text" v-model="form.company" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.status">
                    <el-form-item label="Статус" prop="status" class="field-phone-number first">
                        <el-select v-model="form.status" style="width: 100%;">
                            <el-option
                                v-for="status in statuses"
                                :key="status.key"
                                :label="status.name"
                                :value="status.key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8" v-if="column.serial">
                    <el-form-item label="Серия" prop="serial">
                        <el-input type="text" v-model="form.serial" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.number">
                    <el-form-item label="Номер" prop="number">
                        <el-input type="text" v-model="form.number" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.started">
                    <el-form-item label="Дата выдачи" prop="started" class="field-date-number first">
                        <el-date-picker
                            v-model="form.started"
                            type="date"
                            format="DD.MM.YYYY"
                            value-format="DD.MM.YYYY"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.to_drive">
                    <el-form-item label="Дата передачи водителю"  class="field-date-number first">
                        <el-date-picker
                            v-model="form.to_drive"
                            type="date"
                            format="DD.MM.YYYY"
                            value-format="DD.MM.YYYY"
                        />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">
                            Редактировать разрешение
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</template>

<script setup>
import {inject, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const tx       = inject('tx')
const route    = useRoute()
const router   = useRouter()
const licenseName = ref('')
const htmlForm = ref(null)

const statuses = [
    {key : 'active', name : 'Действует'},
    {key : 'stopped', name : 'Приостановлено'},
    {key : 'canceled', name : 'Аннулировано'},
    {key : 'saved', name : 'На хранении'},
]

const column = reactive({
    region  : false,
    company : false,
    serial  : false,
    number  : false,
    started : false,
    to_drive: false,
    status  : false,
})

tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/licenses/' + route.params.license_id).then(license => {
    licenseName.value = (license.license.serial || '') + ' ' + (license.license.number || '')
    license.fields.forEach(el => {
        column[el] = true
    })
    form.region  = license.license.region
    form.company = license.license.company
    form.serial  = license.license.serial
    form.number  = license.license.number
    form.started = license.license.started
    form.to_drive= license.license.to_drive
    form.status  = license.license.status
})

const form = reactive({
    region  : null,
    company : null,
    serial  : null,
    number  : null,
    started : null,
    to_drive: null,
    status  : null,
})

const rules = reactive({
    region: {required: true, message: 'Поле "Регион" обязательно для заполнения', trigger: 'blur'},
    company: {required: true, message: 'Поле "Лицензиар" обязательно для заполнения', trigger: 'blur'},
    serial: {required: true, message: 'Поле "Серия" обязательно для заполнения', trigger: 'blur'},
    number: {required: true, message: 'Поле "Номер" обязательно для заполнения', trigger: 'blur'},
    started: {required: true, message: 'Поле "Дата выдачи" обязательно для заполнения', trigger: 'blur'},
})

const submit = function(){
    htmlForm.value.validate((isValid) => {
        if(isValid){
            tx.service.query.v1.put('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/licenses/' + route.params.license_id, form).then(() => {
                router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/licenses')
                tx.service.successEdit()
            })
        }
    })
}
</script>
