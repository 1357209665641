<template>
    <el-row>
        <el-col :span="18">
            <h2 class="page-title">Типы отчетов</h2>
        </el-col>
    </el-row>
    <br>
    <el-row>
        <el-col :span="4">
            <el-button @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/reports/delete-pay')" v-if="tx.service.user.right('confirm_delete_pay', route.params.department_id)">Удаленные оплаты</el-button>
        </el-col>
        <el-col :span="4">
            <el-button @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/reports/write-off')" v-if="tx.service.user.right('report_write_off', route.params.department_id)">Списания</el-button>
        </el-col>
    </el-row>

</template>
<script setup>
import {inject} from "vue";
import {useRouter, useRoute} from "vue-router";

const router = useRouter()
const route = useRoute()
const tx = inject('tx');
</script>
