<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Права доступа сотрудника - {{employee.name}}</span>
                <el-button @click="close" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-row>
            <el-col :span="24">
                <h2 class="page-title">Доступы</h2>
            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">

                <el-form label-position="left">
                    <el-form-item label="Выберите тип доступов">
                        <el-select
                            v-model="currentTypePrivilege"
                            @change="changeSettingPrivilege"
                        >
                            <el-option
                                v-for="item in typePrivileges"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="Выберите организацию"
                        v-if="currentTypePrivilege === 'company' || currentTypePrivilege === 'department'"
                    >
                        <el-select
                            v-model="currentCompany"
                            no-data-text="Нет доступных организаций"
                            @change="changeSettingPrivilege"
                        >
                            <el-option
                                v-for="item in typeCompany"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                    <el-form-item
                        label="Выберите подразделение"
                        v-if="currentTypePrivilege === 'department' && currentCompany"
                    >
                        <el-select
                            v-model="currentDepartment"
                            no-data-text="Нет доступных подразделений"
                            @change="changeSettingPrivilege"
                        >
                            <el-option
                                v-for="item in typeDepartment[currentCompany]"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id"
                            />
                        </el-select>
                    </el-form-item>
                </el-form>

            </el-col>
        </el-row>
        <el-row>
            <el-col :span="24">
                <el-table
                    :data="data"
                    class="table-max-width"
                    row-key="code"
                    default-expand-all
                >
                    <el-table-column prop="name" label="Наименование" />
                    <el-table-column>
                        <template #default="scope">
                            <el-switch
                                v-model="scope.row.access"
                                v-if="null !== scope.row.access"
                                @change="setAccess(scope.row)"/>
                        </template>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
    </el-card>
</template>

<script setup>

import {inject, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const tx = inject('tx');
const route = useRoute()
const router = useRouter()
const employee = ref({
    id : 0,
    name : '',
});

const currentTypePrivilege = ref('main')
const currentCompany       = ref('')
const currentDepartment    = ref('')

const typePrivileges = [
    {value: 'main', label: 'Общие'},
    {value: 'company', label: 'Для организации'},
    {value: 'department', label: 'Для подразделения'},
]
const typeCompany    = ref([])
const typeDepartment = ref({})

const data = ref([]);

const changeSettingPrivilege = () => {
    if(currentTypePrivilege.value === 'main'){
        currentCompany.value = ''
        currentDepartment.value = ''
        get();
    }else if(currentTypePrivilege.value === 'company' && currentCompany.value !== ''){
        currentDepartment.value = ''
        get();
    }else if(currentTypePrivilege.value === 'department' && currentCompany.value && currentDepartment.value){
        get();
    }
}



const get = function() {
    tx.service.query.v1.post('/companies/' + route.params.company_id + '/employees/' + route.params.employee_id +'/privileges',{
        type      : currentTypePrivilege.value,
        company   : currentCompany.value,
        department: currentDepartment.value
    }, true).then(response => {
        tx.service.loading = false;
        if(response.status === 200){
            response.json().then(result => {
                data.value = result.privileges
                typeCompany.value = result.companies
                typeDepartment.value = result.departments
                employee.value = result.employee
            })
        }else if(response.status === 422){
            response.json().then(result => {
                tx.service.notify({
                    type: 'error',
                    message : result.message,
                })
            })
        }else{
            tx.service.error();
        }
    })
}

get()

const setAccess = (row) => {
  tx.service.loading = true;
    tx.service.query.v1.put('/companies/' + route.params.company_id + '/employees/' + route.params.employee_id +'/privileges/store',row).then(() => get())
}
const close = () => {
    router.push('/companies/' + route.params.company_id + '/employees')
}

</script>
