<template>
    <div
        class="context-table-menu-item"
        @click="emit('use')"
        v-if="(props.data.privilege_code && tx.service.user.right(props.data.privilege_code, props.data.many_id)) || (props.data.privilege_code_or && tx.service.user.right(props.data.privilege_code_or, props.data.many_id))"
    >{{props.data.title}}</div>
</template>
<script setup>
import {inject} from "vue";

const props = defineProps({
    data: {
        type : Object,
        default : () => {
            return {
                privilege_code : false,
                privilege_code_or : false,
                many_id : 0,
                title : ''
            }
        }
    },
})
const tx    = inject('tx');
const emit  = defineEmits(['use'])
</script>
