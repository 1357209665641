import Company from "@/pages/companies/index.vue";
import Auto from "@/components/auto/ListAuto.vue";

export default [
    {
        path     : '/companies/:company_id/rates/create',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'create-rate-company'})
    },
    {
        path     : '/companies/:company_id/rates/:rate_id',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'update-rate-company'})
    },
    {
        path     : '/companies/:company_id/rates',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'list-rate-company'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/rates/create',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'create-rate-department'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/rates/:rate_id',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'update-rate-department'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/rates',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'list-rate-department'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/rates/create',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'create-rate-auto'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/rates/:rate_id',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'update-rate-auto'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/rates',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'list-rate-auto'})
    }
]
