import {createRouter, createWebHistory } from 'vue-router'

import routeDepartments from './departments'
import routeCompanies from './companies'
import routeEmployees from './employees'
import routeRates from './rates'
import routeAutos from './autos'
import routeLicenses from './licenses'
import routeTo from './technical-cards'
import routePay from './pays'
import routeReport from './reports'

import Root from '../pages/root'
import NotFound from '../pages/root/notfound'

import Auto from '@/pages/autos/index.vue'
import Report from '@/pages/reports/index.vue'

const routes = [
    {
        path     : '/:pathMatch(.*)*',
        name     : '404',
        component: NotFound,
    },
    {
        path     : '/',
        component: Root,
        meta     : {
            action: '/'
        }
    },
    ...routeDepartments,
    ...routeCompanies,
    ...routeEmployees,
    ...routeRates,
    ...routeAutos,
    ...routeLicenses,
    ...routeTo,
    ...routePay,
    ...routeReport,
    {
        path     : '/autos',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        }
    },
    {
        path     : '/reports',
        component: Report,
        meta     : {
            auth  : true,
            action: '/reports'
        },
    },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  previous : null,
})

router.beforeEach((to, from, next) => {
    router.previous = from.name;
    next()
})

export default router
