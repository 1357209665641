import Company from "@/pages/companies/index.vue";

export default [
    {
        path     : '/companies',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : {child_component : 'list-company'}
    },
    {
        path     : '/companies/create',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : {child_component : 'create-company'}
    },
    {
        path     : '/companies/:company_id',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'update-company'})
    },
]
