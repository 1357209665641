<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Добавить разрешение - {{department.name}}</span>
                <el-button @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/reports/write-off')" class="right-button" type="danger" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
            class="form-max-width"
        >

            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item label="Сумма списания" prop="total">
                        <el-input-number :controls="false" v-model="form.total"  style="width: 100%;"/>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">
                            Добавить списание
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
    </el-card>
</template>

<script setup>
import {inject, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const tx       = inject('tx')
const route    = useRoute()
const router   = useRouter()
const htmlForm = ref(null)

const statuses = [
    {key : 'active', name : 'Действует'},
    {key : 'stopped', name : 'Приостановлено'},
    {key : 'canceled', name : 'Аннулировано'},
    {key : 'saved', name : 'На хранении'},
]

const department    = ref({
    id  : 0,
    name: ''
})
tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id).then(res => {
    department.value = res;
})

const form = reactive({
    total  : '',
})

const rules = reactive({
    total: {required: true, message: 'Поле "Сумма списания" обязательно для заполнения', trigger: 'blur'},
})

const submit = function(){
    htmlForm.value.validate((isValid) => {
        if(isValid){
            tx.service.query.v1.post('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/reports/write-off/create', form).then(() => {
                router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/reports/write-off')
                tx.service.successAdd()
            })
        }
    })
}
</script>
