<template>
    <el-row>
        <el-col :span="18">
            <h2 class="page-title">Отчет по удаленным оплатам - {{department.name}}</h2>
        </el-col>
    </el-row>
    <el-row style="padding: 15px 0;">
        <el-col :span="4">
            <el-form-item label="Подтвержденные">
                <el-switch v-model="newPay" @change="start"/>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="24">
            <el-table v-if="loaded" :data="pays" class="table-max-width">
                <el-table-column label="#" width="80">
                    <template #default="scope" v-if="showExpand">
                        <el-popover
                            v-model:visible="scope.row.visible"
                            popper-class="context-table-menu"
                            placement="right"
                            trigger="click"
                            width="200px"
                        >
                            <template #reference>
                                <el-icon class="icon-cursor-pointer"><Expand /></el-icon>
                            </template>
                            <contextElement v-for="item in context"
                                            :data="item.data(scope.row.id)"
                                            @use="item.event(scope.row)"
                            />
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="period" label="Оплаченный период" width="250"/>
                <el-table-column prop="creator" label="Кто вносил" width="250"/>
                <el-table-column prop="total" label="Сумма" width="120"/>
                <el-table-column prop="created_at" label="Дата внесения" width="120"/>
                <el-table-column prop="deleter" label="Кто удалил" width="120"/>
                <el-table-column prop="reason" label="Причина удаления" width="120"/>
                <el-table-column prop="deleted_at" label="Когда удалил" width="120"/>
                <el-table-column label="Авто" width="200">
                    <template #default="scope">
                        {{scope.row.auto}}
                    </template>
                </el-table-column>
                <el-table-column prop="controller" label="Кто подтвердил" width="120"/>
                <el-table-column prop="updated_at" label="Когда подтвердил" width="120"/>
            </el-table>
        </el-col>
    </el-row>

</template>
<script setup>
import contextElement from '@/components/main/contextElement.vue'
import {computed, inject, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";

const tx            = inject('tx')
const route         = useRoute()
const router        = useRouter()
const departmentUrl = '/companies/' + route.params.company_id + '/departments/' + route.params.department_id
const department    = ref({
    id  : 0,
    name: ''
})
const pays = ref([]);
tx.service.query.v1.get(departmentUrl).then(res => {
    department.value = res;
})
const newPay = ref(false);
const loaded = ref(false)
const context = [
    {
        data : () => {
            return{
                privilege_code: 'confirm_delete_pay',
                many_id       : route.params.department_id,
                title         : 'Подтвердить удаление'
            }
        },
        event: (row) => {
            tx.service.query.v1.post(departmentUrl + '/autos/'+ row.auto_id +'/pays/' + row.id + '/confirm',{}).then(() => {
                start()
            })
            row.visible = false
        }
    },
    {
        data : () => {
            return{
                privilege_code: 'confirm_delete_pay',
                many_id       : route.params.department_id,
                title         : 'Востановить'
            }
        },
        event: (row) => {
            tx.service.query.v1.post(departmentUrl + '/autos/'+ row.auto_id +'/pays/' + row.id + '/repair',{}).then(() => {
                start()
            })
            row.visible = false
        }
    },
]
const showExpand = computed(() => {
    let show = false;
    context.forEach(el => {
        if(tx.service.user.right(el.data().privilege_code, -1))
            show = true;
    })
    return show
})
const start = () => {
    loaded.value = false
    tx.service.query.v1.post(departmentUrl + '/reports/delete-pay', {new : newPay.value}, true).then(response => {
        tx.service.loading = false;
        if (response.status === 200) {
            response.json().then(result => {
                pays.value = result
            }).finally(() => {
                loaded.value = true
            })
        } else if (response.status === 422) {
            response.json().then(result => {
                tx.service.notify({
                    type   : 'error',
                    message: result.message,
                })
            })
        } else {
            tx.service.error();
        }
    })
}
start()

</script>
