<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Добавить организацию</span>
                <el-button @click="router.push('/companies')" type="danger" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
            class="form-max-width"
            v-if="tx.service.user.right('create_company')"
        >
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item label="Наименование организации" prop="name">
                        <el-input type="text" v-model="form.name" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">Добавить организацию</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <access-denied v-else />
    </el-card>
</template>

<script setup>

import {inject, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const tx = inject('tx');
const route = useRoute()
const router = useRouter()
const htmlForm = ref(null)
const rules = reactive({
    name : { required: true, message: 'Поле "Наименование обязательно для заполнения"', trigger: 'blur' },
});
const form = reactive({
    name : ''
})
const submit = () => {
    htmlForm.value.validate((isValid) => {
        if (isValid) {
            tx.service.query.v1.post('/companies',form).then(() => {
              tx.service.app.authentication(true).then(() => {
                tx.service.successAdd()
                router.push('/companies')
              })
            })
        }
    });
}
</script>
