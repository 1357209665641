<template>
    <el-row>
        <el-col :span="18">
            <h2 class="page-title">Организации</h2>
        </el-col>
        <el-col :span="6">
            <el-button
                class="right-button"
                @click="router.push('/companies/create')"
                v-if="tx.service.user.right('create_company')"
            >Добавить организацию</el-button>
        </el-col>
    </el-row>
    <el-row>
        <el-col :span="24">
            <el-table v-if="loaded" :data="companies" class="table-max-width">
                <el-table-column label="#" width="60">
                    <template #default="scope">
                        <el-popover v-model:visible="scope.row.visible" popper-class="context-table-menu" placement="right" trigger="click" v-if="showExpand">
                            <template #reference>
                                <el-icon  class="icon-cursor-pointer"><Expand /></el-icon>
                            </template>
                            <contextElement v-for="item in context"
                                            :data="item.data(scope.row.id)"
                                            @use="item.event(scope.row)"
                            />
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column prop="name" label="Название" />
            </el-table>
        </el-col>
    </el-row>


    <el-drawer
        v-model="drawer.visible"
        modal-class="custom-drawer"
        size="80%"
        :withHeader="false"
        show-close
        destroy-on-close
        :close-on-click-modal="false"
        :close-on-press-escape="false"
    >
        <component :is="child_component" />
    </el-drawer>
</template>
<script setup>

import contextElement from '@/components/main/contextElement.vue'
import {computed, inject, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";


const tx = inject('tx');
const route = useRoute()
const router = useRouter()
const companies = ref([]);
const props = defineProps({
    child_component: String
})
const loaded = ref(false)
const drawer = reactive({
    visible : false,
    close : () => {
        router.push('/companies')
        drawer.visible = false;
    },
    open : function(){
        drawer.visible = true;
    }
})
const start = () => {
    if(props.child_component !== 'list-company')
        drawer.open();
    else{
        drawer.close();
        loaded.value = false
        tx.service.query.v1.get('/companies').then(responseCompanies => {
            companies.value = responseCompanies.map(el => {
                el.visible = false;
                return el;
            });
        }).finally(() => {
            loaded.value = true
        })
    }
}
watch(() => props.child_component,() => {
    start()
})
start()
const context = [
    {
        data : (manyId) => {
            return{
                privilege_code: 'update_company',
                many_id       : manyId,
                title         : 'Редактировать'
            }
        },
        event: (row) => {
            router.push('/companies/'+row.id)
            drawer.open()
            row.visible = false
        }
    },
    {
        data : () => {
            return {
                privilege_code   : 'show_department',
                privilege_code_or: 'create_department',
                many_id          : -1,
                title            : 'Подразделения'
            }
        },
        event: (row) => {
            router.push('/companies/'+row.id+'/departments')
            drawer.open()
            row.visible = false
        }
    },
    {
        data : (manyId) => {
            return{
                privilege_code: 'show_user',
                many_id       : manyId,
                title         : 'Сотрудники'
            }
        },
        event: (row) => {
            router.push('/companies/'+row.id+'/employees')
            drawer.open()
            row.visible = false
        }
    },
    {
        data : (manyId) => {
            return{
                privilege_code: 'show_rate_company',
                many_id       : manyId,
                title         : 'Тарифы организации'
            }
        },
        event: (row) => {
            router.push('/companies/'+row.id+'/rates')
            drawer.open()
            row.visible = false
        }
    },
];
const showExpand = computed(() => {
    let show = false;
    context.forEach(el => {
        if(tx.service.user.right(el.data().privilege_code, -1) || ('privilege_code_or' in el.data() && tx.service.user.right(el.data().privilege_code_or, -1)))
            show = true;
    })
    return show
})
</script>
