import Auto from "@/components/auto/ListAuto.vue";

export default [
    {
        path     : '/companies/:company_id/departments/:department_id/autos',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'list-auto'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/create',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'create-auto'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'update-auto'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/drivers',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'list-driver'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/drivers/create',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'create-driver'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/drivers/:driver_id',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'update-driver'})
    }
]
