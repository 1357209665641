<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Список водителей - {{autoName}}</span>
                <el-button type="danger" @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos')" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-row>
            <el-col :span="18"></el-col>
            <el-col :span="6">
                <el-button
                    class="right-button"
                    @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/drivers/create')"
                    v-if="tx.service.user.right('create_driver', route.params.department_id)"
                >Добавить водителя</el-button>
            </el-col>
        </el-row>
        <el-row style="padding: 15px 0;">
            <el-col :span="4">
                <el-button @click="system.filter.visible = true">Фильтр</el-button>
            </el-col>
            <el-col :span="20" style="display: flex; justify-content: end;">
                <el-pagination
                    style="margin-left: auto;"
                    v-model:current-page="system.pagination.current"
                    v-model:page-size="system.pagination.pageSize"
                    :page-sizes="system.pagination.sizes"
                    :hide-on-single-page="!system.pagination.total"
                    :layout="system.pagination.layout"
                    :total="system.pagination.total"
                    @size-change="get"
                    @current-change="get"
                />
            </el-col>
        </el-row>
        <el-table :data="drivers" v-if="loaded" class="table-max-width">
            <el-table-column label="#" width="40">
                <template #default="scope">
                    <el-popover
                        v-model:visible="scope.row.visible"
                        popper-class="context-table-menu"
                        placement="right"
                        trigger="click"
                        v-if="showExpand"
                    >
                        <template #reference>
                            <el-icon
                                class="icon-cursor-pointer"
                            ><Expand /></el-icon>
                        </template>
                        <template v-for="item in context">
                            <contextElement
                                v-if="item.show(scope.row)"
                                :data="item.data(scope.row.id)"
                                @use="item.event(scope.row)"
                            />
                            <span v-else />
                        </template>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column width="40">
                <template #default="scope">
                    <el-icon size="15px" color="green" v-if="!scope.row.connected">
                        <CircleCheckFilled />
                    </el-icon>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="ФИО" v-if="column.name" width="250"/>
            <el-table-column prop="gender" label="Пол" v-if="column.gender" width="100">
                <template #default="scope">
                    {{scope.row.gender == 'm' ? 'Мужской' : 'Женский'}}
                </template>
            </el-table-column>
            <el-table-column prop="phone" label="Телефон" v-if="column.phone" width="200"/>
            <el-table-column prop="active" label="Активность" v-if="column.active" width="100">
                <template #default="scope">
                    {{scope.row.active ? 'Активен' : 'Неактивен'}}
                </template>
            </el-table-column>
            <el-table-column label="Дополнительные контакты" width="210" v-if="column.contacts">
                <template #default="scope">
                    <div v-for="contact in scope.row.contacts">{{contact.contact}}<template v-if="contact.note"> ({{contact.note}})</template></div>
                </template>
            </el-table-column>
            <el-table-column prop="status" label="Статус" v-if="column.status" width="150">
                <template #default="scope">
                    {{scope.row.status == 'sz' ? 'Самозанятый' : (scope.row.status == 'ip' ? 'ИП' : 'Трудоустроен')}}
                </template>
            </el-table-column>
            <el-table-column prop="right_experience" label="В/У Стаж" v-if="column.right_experience" width="80"/>
            <el-table-column prop="birthday" label="Дата рождения" v-if="column.birthday" width="100"/>
            <el-table-column prop="register" label="Дата регистрации" v-if="column.register" width="100"/>
            <el-table-column prop="unregister" label="Дата прекращения регистрации" v-if="column.unregister" width="100"/>
            <el-table-column prop="inn" label="ИНН" v-if="column.inn" width="200"/>
            <el-table-column prop="right_country" label="В/У Страна выдачи" v-if="column.right_country" width="150"/>
            <el-table-column prop="right_number" label="В/У номер" v-if="column.right_number" width="100"/>
            <el-table-column prop="right_create" label="В/У Дата выдачи" v-if="column.right_create" width="100"/>
            <el-table-column prop="identifier_card" label="Документ удостоверяющий личность" v-if="column.identifier_card" width="200"/>
            <el-table-column prop="register_address" label="Адрес регистрации (прописка)" v-if="column.register_address" width="200"/>
        </el-table>
    </el-card>

    <el-dialog v-model="system.filter.visible" :show-close="false">
        <template #header>
            <div style="display: flex;margin-right: -15px;">
                <span class="page-title">Укажите данные для фильтрации</span>
                <el-button @click="system.filter.visible = false" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form :model="system.filter" ref="filterForm" label-position="top">
            <el-row :gutter="15">
                <el-col :span="8" v-if="column.name">
                    <el-form-item label="ФИО" prop="name">
                        <el-input type="text" v-model="system.filter.name" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.phone">
                    <el-form-item label="Телефон" prop="phone">
                        <el-input placeholder="+7 (###) ###-##-##" v-maska data-maska="+7 (###) ###-##-##" v-model="system.filter.phone" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.status">
                    <el-form-item label="Статус">
                        <el-select v-model="system.filter.status" style="width: 100%;">
                            <el-option
                                v-for="status in statuses"
                                :key="status.key"
                                :label="status.name"
                                :value="status.key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="column.gender">
                    <el-form-item label="Пол">
                        <el-select v-model="system.filter.gender" style="width: 100%;">
                            <el-option
                                v-for="gender in genders"
                                :key="gender.key"
                                :label="gender.name"
                                :value="gender.key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8" >
                    <el-form-item label="Привязка к авто">
                        <el-select v-model="system.filter.uses" style="width: 100%;">
                            <el-option
                                v-for="use in uses"
                                :key="use.key"
                                :label="use.name"
                                :value="use.key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Активность" v-if="column.active">
                        <el-switch v-model="system.filter.active" />
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <el-row :gutter="15">
                <el-col :span="8"></el-col>
                <el-col :span="8">
                    <el-button type="danger" style="width: 100%;" @click="clearFilter">Отменить</el-button>
                </el-col>
                <el-col :span="8">
                    <el-button style="width: 100%;" @click="get">Применить</el-button>
                </el-col>
            </el-row>
        </template>
    </el-dialog>

</template>
<script setup>
import {computed, inject, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import contextElement from '@/components/main/contextElement.vue'
import { vMaska } from "maska"
import {Check, CircleCheckFilled} from "@element-plus/icons-vue";

const tx          = inject('tx')
const route = useRoute()
const router = useRouter()
const drivers = ref([])
const autoName = ref('')
const fields   = ref([])
const loaded = ref(false)
const system   = reactive({
    pagination: {
        current : 1,
        pageSize: 10,
        sizes   : [10, 20, 50, 100],
        total   : 1,
        layout  : "jumper, prev, pager, next, total, sizes",
    },
    filter    : {
        visible    : false,
        name       : '',
        phone      : '',
        gender     : 'all',
        status     : 'all',
        uses       : 'yes',
        active     : true,
    }
});
const column = reactive({
        name                : false,
        birthday            : false,
        status              : false,
        register            : false,
        unregister          : false,
        inn                 : false,
        phone               : false,
        right_country       : false,
        right_number        : false,
        right_create        : false,
        right_experience    : false,
        identifier_card     : false,
        register_address    : false,
        gender              : false,
        contacts            : false,
        active              : false,
})
const statuses = [
    {key : 'sz', name : 'Самозанятый'},
    {key : 'ip', name : 'Индивидуальный предприниматель'},
    {key : 'td', name : 'Трудоустроенный'},
    {key : 'all', name : 'Все'}
]
const genders = [
    {key : 'm', name : 'Мужской'},
    {key : 'w', name : 'Женский'},
    {key : 'all', name : 'Все'}
];
const uses = [
    {key : 'yes', name : 'Привязан'},
    {key : 'no', name : 'Не привязан'},
    {key : 'all', name : 'Все'}
];
tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id).then(auto => {
    autoName.value = (auto.brand || '') + ' ' + (auto.model || '') + ' ' + (auto.number || '')
})
const get = function() {
    loaded.value = false
    tx.service.query.v1.post('/companies/' + route.params.company_id + '/departments/' + route.params.department_id +  '/autos/' + route.params.auto_id + '/drivers', system, true).then(response => {
        system.filter.visible = false;
        tx.service.loading = false;
        if (response.status === 200) {
            response.json().then(result => {
                result.fields.forEach(el => {
                    column[el] = true
                })
                drivers.value = result.drivers.map(driver => {
                    driver.edited = true
                    driver.connected = !driver.autos.filter(auto => auto.id == route.params.auto_id).length
                    return driver
                })
                system.pagination.total = result.count
            }).finally(() => {
                loaded.value = true
            })
        } else if (response.status === 422) {
            response.json().then(result => {
                tx.service.notify({
                    type   : 'error',
                    message: result.message,
                })
            })
        } else {
            tx.service.error();
        }
    })
}
get()
const context = [
    {
        data : (manyId) => {
            return{
                privilege_code: 'update_driver',
                many_id       : route.params.department_id,
                title         : 'Редактировать'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/drivers/' + row.id)
        },
        show: (row) => {
            return row.edited
        }
    },
    {
        data : () => {
            return{
                privilege_code: 'add_driver_auto',
                many_id       : route.params.department_id,
                title         : 'Связать с текущим авто'
            }
        },
        event: (row) => {
            tx.service.query.v1.put('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/drivers/' + row.id + '/connect',{}).then(() => {
                get();
            });
        },
        show: (row) => {
            return row.connected
        }
    },
    {
        data : () => {
            return{
                privilege_code: 'add_driver_auto',
                many_id       : route.params.department_id,
                title         : 'Отвязать от текущего авто'
            }
        },
        event: (row) => {
            tx.service.query.v1.put('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/drivers/' + row.id + '/connect',{}).then(() => {
                get();
            });
        },
        show: (row) => {
            return !row.connected
        }
    }
]
const showExpand = computed(() => {
    let show = false;
    context.forEach(el => {
        if(tx.service.user.right(el.data().privilege_code, -1))
            show = true;
    })
    return show
})
const clearFilter = () => {
    system.filter = {
        visible    : false,
        name       : '',
        phone      : '',
        gender     : 'all',
        status     : 'all',
        uses       : 'yes',
        active     : true,
    }
    get()
}
</script>
