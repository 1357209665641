import Company from "@/pages/companies/index.vue";

export default [
    {
        path     : '/companies/:company_id/departments/create',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'create-department'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'update-department'})
    },
    {
        path     : '/companies/:company_id/departments',
        component: Company,
        meta     : {
            auth  : true,
            action: '/companies'
        },
        props : route => ({child_component : 'list-department'})
    }
]
