<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Редактировать водителя - {{driverName}}</span>
                <el-button type="danger" @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/drivers')" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
            class="form-max-width"
        >
            <el-row :gutter="15">
                <el-col :span="8"  v-if="form.last_name !== null">
                    <el-form-item label="Фамилия" prop="last_name">
                        <el-input type="text" v-model="form.last_name" />
                    </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="form.first_name !== null">
                    <el-form-item label="Имя" prop="first_name">
                        <el-input type="text" v-model="form.first_name" />
                    </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="form.second_name !== null">
                    <el-form-item label="Отчество" prop="second_name">
                        <el-input type="text" v-model="form.second_name" />
                    </el-form-item>
                </el-col>

                <el-col :span="8"  v-if="form.birthday !== null">
                    <el-form-item label="Дата рождения" prop="birthday" class="field-date-number first">
                        <el-date-picker
                            v-model="form.birthday"
                            type="date"
                            format="DD.MM.YYYY"
                            value-format="DD.MM.YYYY"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="form.gender !== null">
                    <el-form-item label="Пол" prop="gender" >
                        <el-select v-model="form.gender" style="width: 100%;">
                            <el-option
                                v-for="status in genders"
                                :key="status.key"
                                :label="status.name"
                                :value="status.key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="form.phone !== null">
                    <el-form-item label="Телефон" prop="phone">
                        <el-input type="text" placeholder="+7 (###) ###-##-##" v-maska data-maska="+7 (###) ###-##-##" v-model="form.phone" />
                    </el-form-item>
                </el-col>
            </el-row>

            <template v-for="(item,index) in form.contact" :key="index"  v-if="form.contact !== null">
                <el-row :gutter="15">
                    <el-col :span="8">
                        <el-form-item :class="['field-phone-number']" label="Тип контакта">
                            <el-select v-model="item.type" style="width: calc(100% - 61px);margin-right: 15px;">
                                <el-option
                                    v-for="type in item.types"
                                    :key="type.value"
                                    :label="type.label"
                                    :value="type.value"
                                />
                            </el-select>
                            <el-button type="danger" @click="removeContact(index)" icon="Close"></el-button>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Контакт">
                            <template v-if="item.type === 'phone'">
                                <el-input placeholder="+7 (###) ###-##-##" @blur="blurContact" v-maska data-maska="+7 (###) ###-##-##" v-model="item.value" />
                            </template>
                            <template v-else>
                                <el-input v-model="item.value" />
                            </template>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="Примечание">
                            <el-input v-model="item.note" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </template>

            <el-row :gutter="15"  v-if="form.contact !== null">
                <el-col :span="8">
                    <el-form-item>
                        <el-button style="width: 100%;" @click="addContact" icon="Check" class="button-add-number">Добавить дополнительный контакт</el-button>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="15">
                <el-col :span="8"  v-if="form.status !== null">
                    <el-form-item label="Статус" prop="status">
                        <el-select v-model="form.status" style="width: 100%;">
                            <el-option
                                v-for="status in statuses"
                                :key="status.key"
                                :label="status.name"
                                :value="status.key"
                            />
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="8"  v-if="form.status_register !== null">
                    <el-form-item label="Дата регистрации" prop="status_register" class="field-date-number first">
                        <el-date-picker
                            v-model="form.status_register"
                            type="date"
                            format="DD.MM.YYYY"
                            value-format="DD.MM.YYYY"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="form.status_unregister !== null">
                    <el-form-item label="Дата прекращения" prop="status_unregister" class="field-date-number first">
                        <el-date-picker
                            v-model="form.status_unregister"
                            type="date"
                            format="DD.MM.YYYY"
                            value-format="DD.MM.YYYY"
                        />
                    </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="form.inn !== null">
                    <el-form-item label="Инн таксопарка" prop="inn" v-if="form.status === 'td'">
                        <el-input type="text" v-model="form.inn" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="15">
                <el-col :span="8"  v-if="form.right_country !== null">
                    <el-form-item label="В/У Страна выдачи" prop="right_country">
                        <el-input type="text" v-model="form.right_country" />
                    </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="form.right_number !== null">
                    <el-form-item label="В/У номер" prop="right_number">
                        <el-input type="text" v-model="form.right_number" />
                    </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="form.right_create !== null">
                    <el-form-item label="В/У Дата выдачи" prop="right_create" class="field-date-number first">
                        <el-date-picker
                            v-model="form.right_create"
                            type="date"
                            format="DD.MM.YYYY"
                            value-format="DD.MM.YYYY"
                        />
                    </el-form-item>
                </el-col>

                <el-col :span="8"  v-if="form.right_experience !== null">
                    <el-form-item label="В/У Стаж" prop="right_experience">
                        <el-input-number :controls="false" v-model="form.right_experience" style="width: 100%;"/>
                    </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="form.identifier_card !== null">
                    <el-form-item label="Документ удостоверяющий личность" prop="identifier_card">
                        <el-input type="text" v-model="form.identifier_card" />
                    </el-form-item>
                </el-col>
                <el-col :span="8"  v-if="form.register_address !== null">
                    <el-form-item label="Адрес регистрации (прописка)" prop="register_address">
                        <el-input type="text" v-model="form.register_address" />
                    </el-form-item>
                </el-col>
                <el-col :span="8" v-if="form.active !== null">
                    <el-form-item label="Активность" >
                        <el-switch v-model="form.active" />
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">
                            Редактировать водителя
                        </el-button>
                    </el-form-item>
                </el-col>
            </el-row>

        </el-form>
    </el-card>
</template>

<script setup>
import {inject, reactive, ref} from "vue";
import { vMaska } from "maska"
import {useRoute, useRouter} from "vue-router";

const tx = inject('tx')
const route = useRoute()
const router = useRouter()
const htmlForm = ref(null)
const driverName = ref('')
const statuses = [
    {key : 'sz', name : 'Самозанятый'},
    {key : 'ip', name : 'Индивидуальный предприниматель'},
    {key : 'td', name : 'Трудоустроенный'}
]
const genders = [
    {key : 'm', name : 'Мужской'},
    {key : 'w', name : 'Женский'}
];
tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/drivers/' + route.params.driver_id).then(driver => {
        driverName.value = driver.name
        if('name' in driver) {
            let name         = driver.name.split(' ')
            form.first_name  = name[1]
            form.last_name   = name[0]
            form.second_name = (name[2] || '')
        }
        form.birthday         = (driver.birthday || null)
        form.status           = (driver.status || null)
        form.gender           = (driver.gender || null)
        form.status_register  = (driver.register || null)
        form.status_unregister= (driver.unregister || null)
        form.inn              = (driver.inn || null)
        form.phone            = (driver.phone || null)
        form.right_country    = (driver.right_country || null)
        form.right_number     = (driver.right_number || null)
        form.right_create     = (driver.right_create || null)
        form.right_experience = (driver.right_experience || null)
        form.identifier_card  = (driver.identifier_card || null)
        form.register_address = (driver.register_address || null)
        if('contacts' in driver) {
            form.contact = driver.contacts.map(el => {
                return {
                    types: [{value: 'phone', label: 'Телефон'}, {value: 'email', label: 'Email'}],
                    type : el.type,
                    value: el.contact,
                    note : el.note,
                    id   : el.id,
                }
            })
        }
        form.active           = 'active' in driver ? !!driver.active : null
})

const form = reactive({
    first_name       : null,
    last_name        : null,
    second_name      : null,
    birthday         : null,
    status           : null,
    gender           : null,
    status_register  : null,
    status_unregister: null,
    inn              : null,
    phone            : null,
    right_country    : null,
    right_number     : null,
    right_create     : null,
    right_experience : null,
    identifier_card  : null,
    register_address : null,
    contact          : null,
    contact_remove   : [],
    active           : null,
})

const rules = reactive({
    first_name: {required: true, message: 'Поле "Имя" обязательно для заполнения', trigger: 'blur'},
    last_name: {required: true, message: 'Поле "Фамилия" обязательно для заполнения', trigger: 'blur'},
    birthday: {required: true, message: 'Поле "Дата рождения" обязательно для заполнения', trigger: 'blur'},
    status_register: {required: true, message: 'Поле "Дата регистрации" обязательно для заполнения', trigger: 'blur'},
    phone: [
        {required: true, message: 'Поле "Телефон" обязательно для заполнения', trigger: 'blur'},
        {min: 18, max: 18, message: 'Поле "Телефон" указано некорректно', trigger: 'blur'}
    ],
    right_country: {required: true, message: 'Поле "Страна выдачи В/У" обязательно для заполнения', trigger: 'blur'},
    right_number: {required: true, message: 'Поле "Номер В/У" обязательно для заполнения', trigger: 'blur'},
    right_create: {required: true, message: 'Поле "Дата выдачи В/У" обязательно для заполнения', trigger: 'blur'},
    right_experience: {required: true, message: 'Поле "Водительский стаж" обязательно для заполнения', trigger: 'blur'},
    identifier_card: {required: true, message: 'Поле "Документ удостоверяющий личность" обязательно для заполнения', trigger: 'blur'},
    register_address: {required: true, message: 'Поле "Адрес регистрации (прописка)" обязательно для заполнения', trigger: 'blur'},
})
const addContact = function(){
    form.contact.push({
        types   : [{value : 'phone',label : 'Телефон'},{value : 'email',label : 'Email'}],
        type    : 'phone',
        value   : '',
        note    : '',
    })
}
const removeContact = function(key){
    form.contact = form.contact.filter((el,index) => {
        if(key != index)
            return true
        else{
            if('id' in el)
                form.contact_remove.push(el.id)
            return false
        }
    });
}

const blurContact = function(){
    form.contact.forEach(el => {
        if(el.type === 'phone' && el.value.length < 18)
            el.value = ''
    })
}

const submit = function(){
    htmlForm.value.validate((isValid) => {
        if(isValid){
            let body = {};
            body.fio                    = form.last_name + ' ' + form.first_name + ' ' + form.second_name
            body.birthday               = form.birthday
            body.phone                  = form.phone
            body.status                 = form.status
            body.register               = form.status_register
            body.unregister             = form.status_unregister
            body.inn                    = form.inn
            body.right_country          = form.right_country
            body.right_number           = form.right_number
            body.right_create           = form.right_create
            body.right_experience       = form.right_experience
            body.identifier_card        = form.identifier_card
            body.register_address       = form.register_address
            body.gender                 = form.gender
            body.contact_remove         = form.contact_remove
            body.active                 = form.active

            if(form.contact !== null)
                body.contacts = form.contact.filter(el => el.value).map(el => {
                    let obj = {};
                    obj['value'] = el.value
                    obj['type'] = el.type
                    obj['note'] = el.note
                    obj['id']   = ('id' in el) ? el.id : 0;
                    return obj;
                });

            tx.service.query.v1.put('/companies/' + route.params.company_id + '/departments/' + route.params.department_id +  '/drivers/' + route.params.driver_id, body).then(() => {
                router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/drivers')
                tx.service.successEdit()
            })
        }
    })
}
</script>
