import Auto from "@/components/auto/ListAuto.vue";

export default [
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/technical-cards',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'list-to'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/technical-cards/create',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'create-to'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/technical-cards/:to_id',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'update-to'})
    }
]
