<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Редактировать тарифа - {{rate.name}}</span>
                <el-button @click="close" class="right-button" type="danger" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
            class="form-max-width"
            v-if="tx.service.user.right('update_rate_auto',route.params.department_id)"
        >
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item label="Наименование" prop="name">
                        <el-input type="text" v-model="form.name" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Стоимость" prop="price">
                        <el-input-number style="width: 100%;" :controls="false" v-model="form.price" />
                    </el-form-item>
                </el-col>
                <el-col :span="8">
                    <el-form-item label="Стоимость" prop="price">
                        <el-switch v-model="form.active" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">Редактировать тариф</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <access-denied v-else />
    </el-card>
</template>

<script setup>

import {inject, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const tx       = inject('tx')
const route    = useRoute()
const router   = useRouter()
const htmlForm = ref(null)
const rules    = reactive({
    name : {required: true, message: 'Поле "Наименование обязательно для заполнения"', trigger: 'blur'},
    price: {required: true, message: 'Поле "Стоимость" обязательно для заполнения', trigger: 'blur'},
});
const form     = reactive({
    name : '',
    price: 0,
})
const rate     = ref({
    id    : 0,
    name  : '',
    active: false
})
tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/rates/' + route.params.rate_id).then(res => {
    rate.value = res;
    form.name = res.name;
    form.price = res.price;
    form.active = !!res.active;
})
const submit = function(){
    htmlForm.value.validate((isValid) => {
        if (isValid) {
            tx.service.query.v1.put('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/rates/' + rate.value.id,{
                name : form.name,
                price : form.price,
                active : form.active,
            }).then(() => {
                close()
            })
        }
    });
}
const close = () => {
    router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/rates')
}
</script>
