import Auto from "@/components/auto/ListAuto.vue";

export default [
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/licenses',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'list-license'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/licenses/create',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'create-license'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/autos/:auto_id/licenses/:license_id',
        component: Auto,
        meta     : {
            auth  : true,
            action: '/autos'
        },
        props : route => ({child_component : 'update-license'})
    }
]
