<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Список лицензий - {{autoName}}</span>
                <el-button type="danger" @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos')" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-row>
            <el-col :span="18"></el-col>
            <el-col :span="6">
                <el-button
                    class="right-button"
                    @click="router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/licenses/create')"
                    v-if="tx.service.user.right('create_license', route.params.department_id)"
                >Добавить лицензию</el-button>
            </el-col>
        </el-row>
        <el-table :data="licenses" v-if="loaded" class="table-max-width">
            <el-table-column label="#" width="40">
                <template #default="scope">
                    <el-popover
                        v-model:visible="scope.row.visible"
                        popper-class="context-table-menu"
                        placement="right"
                        trigger="click"
                        v-if="showExpand"
                    >
                        <template #reference>
                            <el-icon
                                class="icon-cursor-pointer"
                            ><Expand /></el-icon>
                        </template>
                        <template v-for="item in context">
                            <contextElement
                                :data="item.data(scope.row.id)"
                                @use="item.event(scope.row)"
                            />
                        </template>
                    </el-popover>
                </template>
            </el-table-column>
            <el-table-column prop="companies" label="Лецинзиар" v-if="column.companies" width="250"/>
            <el-table-column prop="region" label="Регион" v-if="column.region" width="250"/>
            <el-table-column prop="started" label="Дата выдачи" v-if="column.started" width="120"/>
            <el-table-column prop="serial" label="Серия" v-if="column.serial" width="120"/>
            <el-table-column prop="number" label="Номер" v-if="column.number" width="120"/>
            <el-table-column prop="to_drive" label="Дата передачи водителю" v-if="column.to_drive" width="120"/>
            <el-table-column prop="status" label="Статус" v-if="column.status" width="120">
                <template #default="scope">
                    {{statuses[scope.row.status]}}
                </template>
            </el-table-column>
        </el-table>
    </el-card>

</template>
<script setup>
import {computed, inject, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import contextElement from '@/components/main/contextElement.vue'

const tx          = inject('tx')
const route = useRoute()
const router = useRouter()
const licenses = ref([])
const autoName = ref('')
const fields   = ref([])
const loaded = ref(false)
const column = reactive({
    region   : false,
    companies: false,
    serial   : false,
    number   : false,
    started  : false,
    status   : false,
    to_drive : false,
})
const statuses = {
    active  : 'Действующее',
    canceled: 'Аннулировано',
    stopped : 'Приостановлено',
    saved   : 'На хранении',
}
tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id).then(auto => {
    autoName.value = (auto.brand || '') + ' ' + (auto.model || '') + ' ' + (auto.number || '')
})
const get = function() {
    loaded.value = false
    tx.service.query.v1.get('/companies/' + route.params.company_id + '/departments/' + route.params.department_id +  '/autos/' + route.params.auto_id + '/licenses', true).then(response => {
        tx.service.loading = false;
        if (response.status === 200) {
            response.json().then(result => {
                result.fields.forEach(el => {
                    column[el] = true
                })
                licenses.value = result.licenses
            }).finally(() => {
                loaded.value = true
            })
        } else if (response.status === 422) {
            response.json().then(result => {
                tx.service.notify({
                    type   : 'error',
                    message: result.message,
                })
            })
        } else {
            tx.service.error();
        }
    })
}
get()
const context = [
    {
        data : (manyId) => {
            return{
                privilege_code: 'update_license',
                many_id       : route.params.department_id,
                title         : 'Редактировать'
            }
        },
        event: (row) => {
            router.push('/companies/' + route.params.company_id + '/departments/' + route.params.department_id + '/autos/' + route.params.auto_id + '/licenses/' + row.id)
        }
    }
]
const showExpand = computed(() => {
    let show = false;
    context.forEach(el => {
        if(tx.service.user.right(el.data().privilege_code, -1))
            show = true;
    })
    return show
})
</script>
