import ListReport from "@/components/report/ListReport.vue";
import DeletePay from "@/components/report/DeletePay.vue";
import ListWriteOff from "@/components/report/ListWriteOff.vue";

export default [
    {
        path     : '/companies/:company_id/departments/:department_id/reports',
        component: ListReport,
        meta     : {
            auth  : true,
            action: '/reports'
        }
    },
    {
        path     : '/companies/:company_id/departments/:department_id/reports/delete-pay',
        component: DeletePay,
        meta     : {
            auth  : true,
            action: '/reports'
        }
    },
    {
        path     : '/companies/:company_id/departments/:department_id/reports/write-off',
        component: ListWriteOff,
        meta     : {
            auth  : true,
            action: '/reports'
        },

        props : route => ({child_component : 'list-write-off'})
    },
    {
        path     : '/companies/:company_id/departments/:department_id/reports/write-off/create',
        component: ListWriteOff,
        meta     : {
            auth  : true,
            action: '/reports'
        },

        props : route => ({child_component : 'create-write-off'})
    },
]
