<template>
    <el-card>
        <template #header>
            <div style="display: flex">
                <span class="page-title">Список сотрудников организации - {{company.name}}</span>
                <el-button @click="router.push('/companies')" class="right-button" type="danger" icon="close">Закрыть</el-button>
            </div>
        </template>
      <el-row>
        <el-col :span="18"></el-col>
        <el-col :span="6">
          <el-button
              class="right-button"
              @click="router.push('/companies/'+route.params.company_id+'/employees/create')"
              v-if="tx.service.user.right('create_user', route.params.company_id)"
          >Добавить сотрудника</el-button>
        </el-col>
      </el-row>
      <el-row>
          <el-col :span="24">
              <el-table :data="employees" class="table-max-width">
                  <el-table-column label="#" width="80">
                      <template #default="scope">
                          <el-popover v-model:visible="scope.row.visible" popper-class="context-table-menu" placement="right" trigger="click" v-if="showExpand">
                              <template #reference>
                                  <el-icon class="icon-cursor-pointer"><Expand /></el-icon>
                              </template>
                              <contextElement v-for="item in context"
                                              :data="item.data(scope.row.id)"
                                              @use="item.event(scope.row)"
                              />
                          </el-popover>
                      </template>
                  </el-table-column>
                  <el-table-column prop="name" label="ФИО" />
                  <el-table-column prop="position" label="Должность" />
                  <el-table-column prop="city" label="Город проживания" />
                  <el-table-column prop="phone" label="Телефон" />
                  <el-table-column label="Дополнительные контакты" width="210">
                      <template #default="scope">
                          <div v-for="contact in scope.row.contacts">{{contact.contact}}<template v-if="contact.note"> ({{contact.note}})</template></div>
                      </template>
                  </el-table-column>
                  <el-table-column label="Статус">
                      <template #default="scope">
                          {{statuses[scope.row.status]}}
                      </template>
                  </el-table-column>
                  <el-table-column label="Организации">
                      <template #default="scope">
                          <div v-for="item in scope.row.companies.map(el => el.name)">
                              {{item}}
                          </div>
                      </template>
                  </el-table-column>
              </el-table>
          </el-col>
      </el-row>
    </el-card>
</template>
<script setup>

import {computed, inject, ref} from "vue";

import contextElement from '@/components/main/contextElement.vue'
import {useRoute, useRouter} from "vue-router";

const tx          = inject('tx')
const route = useRoute()
const router = useRouter()
const employees = ref([]);
const statuses = {
    work    : 'Работает',
    holiday : 'Отпуск/Больничный',
    fired   : 'Уволен',
}
const company = ref({
    id : 0,
    name: ''
})
tx.service.query.v1.get('/companies/' + route.params.company_id).then(res => {
  company.value = res;
})
const context = [
    {
        data : (manyId) => {
            return{
                privilege_code: 'update_user',
                many_id       : company.value.id,
                title         : 'Редактировать'
            }
        },
        event: (row) => {
            router.push('/companies/' + company.value.id + '/employees/' + row.id)
            row.visible = false
        }
    },
    {
        data : (manyId) => {
            return{
                privilege_code: 'access_user',
                many_id       : company.value.id,
                title         : 'Права доступа'
            }
        },
        event: (row) => {
            router.push('/companies/' + company.value.id + '/employees/' + row.id + '/privileges')
            row.visible = false
        }
    },
]
const showExpand = computed(() => {
  let show = false;
  context.forEach(el => {
    if(tx.service.user.right(el.data().privilege_code, -1))
      show = true;
  })
  return show
})
const get = function() {
  tx.service.query.v1.get('/companies/' + route.params.company_id + '/employees').then(res => {
    employees.value = res.map(el => {
      el.visible = false;
      el.edit = false;
      return el;
    });
  })
}
get()
</script>
