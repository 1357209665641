import { createApp } from 'vue'
import App from './App.vue'

import 'normalize.css/normalize.css'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import locale from 'element-plus/es/locale/lang/ru'

import * as ElementPlusIconsVue from '@element-plus/icons-vue'

import accessDenied from "@/components/main/accessDenied.vue"

import './assets/main.css'

import router from './router'

import CreateCompany from '@/components/company/CreateCompany.vue'
import UpdateCompany from '@/components/company/UpdateCompany.vue'
import CreateDepartment from '@/components/company/CreateDepartment.vue'
import ListDepartment from '@/components/company/ListDepartment.vue'
import UpdateDepartment from '@/components/company/UpdateDepartment.vue'
import CreateEmployee from '@/components/company/CreateEmployee.vue'
import ListEmployee from '@/components/company/ListEmployee.vue'
import UpdateEmployee from '@/components/company/UpdateEmployee.vue'
import PrivilegeEmployee from '@/components/company/PrivilegeEmployee.vue'
import CreateRateCompany from '@/components/company/CreateRateCompany.vue'
import ListRateCompany from '@/components/company/ListRateCompany.vue'
import UpdateRateCompany from '@/components/company/UpdateRateCompany.vue'
import CreateRateDepartment from '@/components/company/CreateRateDepartment.vue'
import ListRateDepartment from '@/components/company/ListRateDepartment.vue'
import UpdateRateDepartment from '@/components/company/UpdateRateDepartment.vue'
import CreateAuto from '@/components/auto/CreateAuto.vue'
import UpdateAuto from '@/components/auto/UpdateAuto.vue'
import CreateDriver from '@/components/auto/CreateDriver.vue'
import ListDriver from '@/components/auto/ListDriver.vue'
import UpdateDriver from '@/components/auto/UpdateDriver.vue'
import ListLicense from '@/components/auto/ListLicense.vue'
import CreateLicense from '@/components/auto/CreateLicense.vue'
import UpdateLicense from '@/components/auto/UpdateLicense.vue'
import ListTo from '@/components/auto/ListTo.vue'
import CreateTo from '@/components/auto/CreateTo.vue'
import UpdateTo from '@/components/auto/UpdateTo.vue'
import CreateRateAuto from '@/components/auto/CreateRateAuto.vue'
import ListRateAuto from '@/components/auto/ListRateAuto.vue'
import UpdateRateAuto from '@/components/auto/UpdateRateAuto.vue'
import CreatePay from '@/components/auto/CreatePay.vue'
import ListPay from '@/components/auto/ListPay.vue'
import UpdatePay from '@/components/auto/UpdatePay.vue'
import CreateWriteOff from '@/components/report/CreateWriteOff.vue'

const VueApp = createApp(App)

VueApp.use(router)

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    VueApp.component(key, component)
}

VueApp.component('create-company', CreateCompany)
VueApp.component('update-company', UpdateCompany)
VueApp.component('create-department', CreateDepartment)
VueApp.component('list-department', ListDepartment)
VueApp.component('update-department', UpdateDepartment)
VueApp.component('create-employee', CreateEmployee)
VueApp.component('list-employee', ListEmployee)
VueApp.component('update-employee', UpdateEmployee)
VueApp.component('privilege-employee', PrivilegeEmployee)
VueApp.component('create-rate-company', CreateRateCompany)
VueApp.component('list-rate-company', ListRateCompany)
VueApp.component('update-rate-company', UpdateRateCompany)
VueApp.component('create-rate-department', CreateRateDepartment)
VueApp.component('list-rate-department', ListRateDepartment)
VueApp.component('update-rate-department', UpdateRateDepartment)
VueApp.component('create-auto', CreateAuto)
VueApp.component('update-auto', UpdateAuto)
VueApp.component('create-driver', CreateDriver)
VueApp.component('list-driver', ListDriver)
VueApp.component('update-driver', UpdateDriver)
VueApp.component('list-license', ListLicense)
VueApp.component('create-license', CreateLicense)
VueApp.component('update-license', UpdateLicense)
VueApp.component('list-to', ListTo)
VueApp.component('create-to', CreateTo)
VueApp.component('update-to', UpdateTo)
VueApp.component('create-rate-auto', CreateRateAuto)
VueApp.component('list-rate-auto', ListRateAuto)
VueApp.component('update-rate-auto', UpdateRateAuto)
VueApp.component('create-pay', CreatePay)
VueApp.component('list-pay', ListPay)
VueApp.component('update-pay', UpdatePay)
VueApp.component('create-write-off', CreateWriteOff)

VueApp.component('access-denied', accessDenied)

VueApp.use(ElementPlus,  {locale})

VueApp.mount('#app')
