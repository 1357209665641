<template>
    <el-card v-if="company">
        <template #header>
            <div style="display: flex">
                <span class="page-title">Редактирование организации - {{company.name}}</span>
                <el-button @click="router.push('/companies')" type="danger" class="right-button" icon="close">Закрыть</el-button>
            </div>
        </template>
        <el-form
            ref="htmlForm"
            :model="form"
            :rules="rules"
            label-position="top"
            class="form-max-width"
            v-if="tx.service.user.right('update_company',company.id)"
        >
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item label="Наименование организации" prop="name">
                        <el-input type="text" v-model="form.name" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="15">
                <el-col :span="8">
                    <el-form-item>
                        <el-button class="button-max-width color-taxi" @click="submit">Редактировать организацию</el-button>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>

        <access-denied v-else />
    </el-card>
</template>

<script setup>

import {inject, reactive, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

const tx = inject('tx');
const route = useRoute()
const router = useRouter()
const htmlForm  = ref(null)
const rules     = reactive({
    name: {required: true, message: 'Поле "Наименование организации" обязательно для заполнения', trigger: 'blur'},
});
const company   = ref(null)
const form = reactive({
    name : ''
})
tx.service.query.v1.get('/companies/' + route.params.company_id).then(res => {
    company.value = res;
    form.name     = res.name;
})
const submit = () => {
    htmlForm.value.validate((isValid) => {
        if (isValid) {
            tx.service.query.v1.put('/companies/' + company.value.id, {
                name: form.name
            }).then(() => {
                router.push('/companies')
                tx.service.successEdit()
            })
        }
    });
}
</script>
